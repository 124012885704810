import { CurrentDirection, HrcsLocation } from "../types";

type EldrigeEntry = {
  [key: string]: { speed: number, direction: CurrentDirection }
}

type EldrigeDataType = {
  [location in HrcsLocation]: {
    H: EldrigeEntry;
    L: EldrigeEntry;
  };
};

export const EldrigeData: EldrigeDataType = {
  Chelsea: {
    H: {
      '0_after': { speed: 1.9, direction: 'flood' },
      '1_after': { speed: 1.7, direction: 'flood' },
      '2_after': { speed: 0.9, direction: 'flood' },
      '3_after': { speed: 0.1, direction: 'ebb' },
      '4_after': { speed: 1.3, direction: 'ebb' },
      '5_after': { speed: 2.2, direction: 'ebb' },
    },
    L: {
      '0_after': { speed: 2.8, direction: 'ebb' },
      '1_after': { speed: 2.6, direction: 'ebb' },
      '2_after': { speed: 1.9, direction: 'ebb' },
      '3_after': { speed: 1.0, direction: 'ebb' },
      '4_after': { speed: 0.3, direction: 'flood' },
      '5_after': { speed: 1.3, direction: 'flood' },
    },
  },
  Inwood: {
    H: {
      '0_after': { speed: 1.7, direction: 'flood' },
      '1_after': { speed: 1.9, direction: 'flood' },
      '2_after': { speed: 1.2, direction: 'flood' },
      '3_after': { speed: 0.3, direction: 'flood' },
      '4_after': { speed: 0.8, direction: 'ebb' },
      '5_after': { speed: 1.7, direction: 'ebb' },
    },
    L: {
      '0_after': { speed: 2.5, direction: 'ebb' },
      '1_after': { speed: 2.6, direction: 'ebb' },
      '2_after': { speed: 2.1, direction: 'ebb' },
      '3_after': { speed: 1.3, direction: 'ebb' },
      '4_after': { speed: 0.2, direction: 'ebb' },
      '5_after': { speed: 1.0, direction: 'flood' },
    },
  }
};