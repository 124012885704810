import React from 'react';
import styled from '@emotion/styled';

const PlanItemRow = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 40px;
  display: flex;
`;

const Title = styled.div`
  white-space: nowrap;
  padding-right: 8px;
  font-weight: bold;
`;

const Line = styled.div`
  width: 100%;
  height: 35px;
  border-bottom: 1px solid gray;
`;

const PlanItem = ({ title, value }: { title: string, value?: string }) => {
  return (
    <PlanItemRow>
      <Title>{title}:</Title> 
      {<Line>{value}</Line>}
    </PlanItemRow>
  );
};

export default PlanItem;
