import { Dayjs } from 'dayjs';
import { HrcsLocation, SailPrediction, TidePrediction } from "../types";
import { EldrigeData } from '../modules/EldridgeData';

const TIDE_INTERVALS: Array<number> = [0, 1, 2, 3, 4, 5];

export const getSailPredictions = (previousTide: TidePrediction | undefined, sailStart: Dayjs | null, sailDuration: string, sailLocation: HrcsLocation): Array<SailPrediction> => {
  if (!previousTide || !sailStart) {
    return [];
  }
  
  const sailPredictions: Array<SailPrediction> = [];

  // 6 hours after previous tide
  TIDE_INTERVALS.forEach(hour => {
    const { direction, speed } = EldrigeData[sailLocation][previousTide.type][`${hour}_after`];
    const date = previousTide.date.add(hour, 'hour');
    sailPredictions.push({
      date,
      currentDirection: direction,
      currentSpeed: speed,
      hoursAfterTide: hour,
      afterTideType: previousTide.type,
      isDateWithinSailDuration: date.isBetween(sailStart, sailStart.add(parseInt(sailDuration), 'hour')),
    })
  });

  // 7-12 hours after previous tide
  TIDE_INTERVALS.forEach(hour => {
    const followingTide = previousTide?.type === 'H' ? 'L' : 'H';
    const { direction, speed } = EldrigeData[sailLocation][followingTide][`${hour}_after`];
    const date = previousTide.date.add(hour + 6, 'hour');
    sailPredictions.push({
      date,
      currentDirection: direction,
      currentSpeed: speed,
      hoursAfterTide: hour,
      afterTideType: followingTide,
      isDateWithinSailDuration: date.isBetween(sailStart, sailStart.add(parseInt(sailDuration), 'hour')),
    })
  });

  const sailDurationInt = parseInt(sailDuration);
  const filteredPredictions = sailPredictions.filter(entry => entry.date.isBetween(sailStart.subtract(1, 'hour'), sailStart.add(sailDurationInt+1, 'hour')))

  return filteredPredictions;
};
