import dayjs from "dayjs";
import { HrcsLocation } from "../types";

export const normalizeQueryParamsData = (searchParams: URLSearchParams) => {
  const startTime = searchParams.get('startTime');
  const endTime = searchParams.get('endTime');
  const date = searchParams.get('date');
  const location = searchParams.get('location') as HrcsLocation;

  if (!startTime || !endTime || !date || !location) {
    return {};
  }

  const start = dayjs(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm A');
  const end = dayjs(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm A');
  const duration = `${end.diff(start, 'hour')}`;

  return {
    start,
    duration,
    location,
    setFromExtensionParams: true,
  };
};
