import React from 'react';
import { Dayjs } from 'dayjs';
import styled from '@emotion/styled'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CurrentIcon from './CurrentIcon';
import { TidePrediction, HrcsLocation } from './types';
import { getSailPredictions } from './modules/SailPredictions';
import { getTideTypeDisplayString } from './modules/TideUtils';

const PredictionsWrapper = styled.div`
  border: 1px solid #aaa;
  margin-bottom: 15px;
`;

const formatPredictionTime = (date: Dayjs, isDateWithinSailDuration: boolean) => {
  const formattedTime = date.format('h:mm A');
  return isDateWithinSailDuration ? <strong>{formattedTime}</strong> : formattedTime;
}

type Props = {
  previousTide: TidePrediction | undefined;
  sailStart: Dayjs | null;
  sailDuration: string;
  sailLocation: HrcsLocation,
  isPrintView?: boolean;
};

const SailPredictions = ({ previousTide, sailStart, sailDuration, sailLocation, isPrintView }: Props) => {
  const sailPredictions = getSailPredictions(previousTide, sailStart, sailDuration, sailLocation);

  return (
    <TableContainer component={PredictionsWrapper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell>Current Direction</TableCell>
            <TableCell>Current Speed (kts)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sailPredictions.map(({ date, currentDirection, currentSpeed, hoursAfterTide, afterTideType, isDateWithinSailDuration}) => (
            <TableRow key={`${formatPredictionTime(date, false)}`}>
              <TableCell style={{ minWidth: '80px' }}>{formatPredictionTime(date, isDateWithinSailDuration)} {!isPrintView && <><br /><em>{hoursAfterTide} hours after<br />{getTideTypeDisplayString(afterTideType)} tide</em></> }</TableCell>
              <TableCell><CurrentIcon direction={currentDirection} /> {currentDirection}</TableCell>
              <TableCell>{currentSpeed.toFixed(1)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export default SailPredictions;