import React from 'react';
import { Dayjs } from 'dayjs';
import styled from '@emotion/styled'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { HrcsLocation } from './types';

const SettingsWrapper = styled.div`
  margin: 40px 0px;
  text-align: center;
`;

const HRCS_LOCATIONS = ['Chelsea', 'Inwood'];
const DURATION_VALUES = ['2', '3', '4', '5', '6', '7'];

type Props = {
    sailStart: Dayjs | null,
    sailDuration: string;
    sailLocation: HrcsLocation,
    setSailStart: (v: Dayjs | null) => void,
    setSailDuration: (v: string) => void,
    setSailLocation: (v: HrcsLocation) => void,
};

const Settings = ({ sailStart, sailDuration, sailLocation, setSailStart, setSailDuration, setSailLocation }: Props) => {
    return (
        <SettingsWrapper>
          <div>
            <h1>Float Plan</h1>
          </div>
          <Grid container columns={5} rowSpacing={2} columnSpacing={1}>
            <Grid item lg={1} />
            <Grid item lg={1} md={5} sm={5} xs={5}>
              <FormControl fullWidth>
                <DateTimePicker
                  label="Sail Start Date/Time"
                  value={sailStart}
                  onChange={(newValue) => setSailStart(newValue)}
                  sx={{ width: '100%' }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={1} md={5} sm={5} xs={5}>
              <FormControl fullWidth>
                <InputLabel id="duration-label">Sail Duration</InputLabel>
                <Select
                  labelId="duration-label"
                  value={sailDuration}
                  label="Sail Duration"
                  onChange={(event: SelectChangeEvent) => setSailDuration(event.target.value)}
                  sx={{ width: '100%' }}
                >
                  {DURATION_VALUES.map(value => <MenuItem key={value} value={value}>{value} hours</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={1} md={5} sm={5} xs={5}>
              <FormControl fullWidth>
                <InputLabel id="location-label">Sail Location</InputLabel>
                <Select
                  labelId="location-label"
                  value={sailLocation}
                  label="Sail Location"
                  onChange={(event: SelectChangeEvent) => setSailLocation(event.target.value as HrcsLocation)}
                  sx={{ width: '100%' }}
                >
                  {HRCS_LOCATIONS.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={1} />
          </Grid>
        </SettingsWrapper>
    );
};

export default Settings;
