import React from 'react';
import { Dayjs } from 'dayjs';
import styled from '@emotion/styled'
import Grid from '@mui/material/Grid';
import SailPredictions from './SailPredictions';
import PlanItem from './PlanItem';
import { getTideString } from './modules/TideUtils';
import { HrcsLocation, TidePrediction } from './types';

const Wrapper = styled.div``;

type Props = {
  sailStart: Dayjs | null,
  sailDuration: string,
  sailLocation: HrcsLocation,
  nextTide: TidePrediction | undefined,
  previousTide: TidePrediction | undefined,
  isPrintView?: boolean,
};

const FloatPlan = ({ sailStart, sailDuration, sailLocation, nextTide, previousTide, isPrintView }: Props) => {
  const smallColumns = isPrintView ? 1 : 3;
  return (
    <Wrapper style={isPrintView ? { margin: '0' } : { margin: '40px 20px' }}>
      <Grid container columns={3} spacing={2}>
        <Grid item lg={1} md={smallColumns} sm={smallColumns} xs={smallColumns}>
          <PlanItem title="Skipper" />
          <PlanItem title="Depart" value={sailStart?.format('M/D/YYYY h:mm A')} />
          <PlanItem title="Return" value={sailStart?.add(parseInt(sailDuration), 'hour').format('M/D/YYYY h:mm A')} />
          <PlanItem title="Previous Tide" value={getTideString(previousTide, 'previous')} />
          <PlanItem title="Next Tide" value={getTideString(nextTide, 'next')} />
          <PlanItem title="Wind/Weather" />
          <PlanItem title="Where you're sailing" />
        </Grid>
        <Grid item lg={1} md={smallColumns} sm={smallColumns} xs={smallColumns}>
          <SailPredictions previousTide={previousTide} sailStart={sailStart} sailDuration={sailDuration} sailLocation={sailLocation} isPrintView={isPrintView} />
        </Grid>
        <Grid item lg={1} md={smallColumns} sm={smallColumns} xs={smallColumns}>
          <PlanItem title="Skipper's #" />
          <PlanItem title="2nd Cell #" />
          <br />
          <PlanItem title="Crew 1" />
          <PlanItem title="Crew 2" />
          <PlanItem title="Crew 3" />
          <PlanItem title="Crew 4" />
          <PlanItem title="Crew 5" />   
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default FloatPlan;