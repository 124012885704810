import dayjs, { Dayjs } from 'dayjs';
import { TidePrediction, TidePredictions, TideType } from "../types";

export const getPreviousTide = (tides: TidePredictions, sailStart: Dayjs | null): TidePrediction | undefined => {
  let prevTide;
  tides.forEach(tide => {
    if (tide.date.isBefore(sailStart)) {
      prevTide = tide;
    }
  });
  return prevTide;
};

export const getNextTide = (tides: TidePredictions, sailStart: Dayjs | null): TidePrediction | undefined => {
  return tides.find(tide => tide.date.isAfter(sailStart));
};

export const normalizeTidePredictions = (predictions: Array<{ t: string, v: string, type: string }>) => {
  return predictions.map<TidePrediction>(({ t, type }) => ({
    date: dayjs(t, 'YYYY-MM-DD HH:mm'),
    type: type as TideType,
  }));
};

export const getTideTypeDisplayString = (tideType: TideType | undefined) => {
  if (!tideType) {
    return '[ERROR -- NO TIDE TYPE]';
  }
  return tideType === 'L' ? 'Low' : 'High';
};

export const getTideString = (tide: TidePrediction | undefined, type: 'previous' | 'next') => {
  if (!tide) {
    return `[This tide is in the ${type} day]`;
  }
  return `${tide.date.format('h:mm A')} ${getTideTypeDisplayString(tide.type)}`;
};