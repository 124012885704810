import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import styled from '@emotion/styled'
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import { getNextTide, getPreviousTide, normalizeTidePredictions } from './modules/TideUtils';
import { HrcsLocation, TidePrediction } from './types';
import Settings from './Settings';
import { useSearchParams } from 'react-router-dom';
import { normalizeQueryParamsData } from './modules/QueryParams';
import FloatPlan from './FloatPlan';

const THE_BATTERY_NOAA_ID = '8518750';

const AppWrapper = styled.div`
  margin: 40px;
  @media print {
    @page {
      size: landscape;
      margin: 0;
    }
  }
`;

const PrintSettings = styled.div`
  text-align: right;
  @media print {
    display: none;
  }
`;

const Footer = styled.div`
  text-align: center;
  font-size: 12px;
  color: #666;
`;

const App = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isPrintView, setPrintView] = useState<boolean>(false);

  const [sailStart, setSailStart] = useState<Dayjs | null>(dayjs());
  const [sailDuration, setSailDuration] = useState<string>('3');
  const [sailLocation, setSailLocation] = useState<HrcsLocation>('Chelsea');
  
  const [previousTide, setPreviousTide] = useState<TidePrediction | undefined>();
  const [nextTide, setNextTide] = useState<TidePrediction | undefined>();

  const handleSetSailStart = (v: Dayjs | null) => setSailStart(v);
  const handleSetSailDuration = (v: string) => setSailDuration(v);
  const handleSetSailLocation = (v: HrcsLocation) => setSailLocation(v);
  const handleSetPrintView = (e: React.ChangeEvent) => setPrintView((e.target as HTMLInputElement).checked);

  useEffect(() => {
    const setDataFromQueryParams = async () => {
      const { setFromExtensionParams, start, duration, location } = normalizeQueryParamsData(searchParams);
      
      if (setFromExtensionParams) {
        setSailDuration(duration);
        setSailLocation(location);
        setSailStart(start);

        setSearchParams();
      }
    };

    setDataFromQueryParams();
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    const fetchTidesForTheBattery = async () => {
      const date = sailStart?.format('YYYYMMDD');
      const response = await fetch(`https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?product=predictions&begin_date=${date}&end_date=${date}&datum=MLLW&station=${THE_BATTERY_NOAA_ID}&time_zone=lst_ldt&units=english&interval=hilo&format=json&application=FloatPlanApp`);
      const responseJson = await response.json();
      const tides = normalizeTidePredictions(responseJson.predictions);
      setPreviousTide(getPreviousTide(tides, sailStart));
      setNextTide(getNextTide(tides, sailStart));
    };

    fetchTidesForTheBattery();
  }, [sailStart]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AppWrapper>
        <PrintSettings>
          Print View <Switch checked={isPrintView} onChange={handleSetPrintView} />
        </PrintSettings>
        {!isPrintView && <Settings sailStart={sailStart} sailDuration={sailDuration} sailLocation={sailLocation} setSailStart={handleSetSailStart} setSailDuration={handleSetSailDuration} setSailLocation={handleSetSailLocation} />}
        <Paper elevation={isPrintView ? 0 : 3}>
          <FloatPlan sailStart={sailStart} previousTide={previousTide} nextTide={nextTide} sailDuration={sailDuration} sailLocation={sailLocation} isPrintView={isPrintView} />
        </Paper>
        {!isPrintView && <Footer>
          This is a work in progress, Slack @Evan Sobkowicz with feedback. 
          <br />
          Accuracy is not guaranteed, and currents can be different from these predictions.
        </Footer>}
      </AppWrapper>
    </LocalizationProvider>
  );
};

export default App;
