import React from 'react';
import { ArrowDownward, ArrowUpward, Height } from '@mui/icons-material';
import { CurrentDirection } from './types';

const CurrentIcon = ({ direction }: { direction: CurrentDirection }) => {
  if (direction === 'ebb') {
    return <ArrowDownward />
  }
  if (direction === 'flood') {
    return <ArrowUpward />
  }
  // slack
  return <Height />
};

export default CurrentIcon;